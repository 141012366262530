import React from "react";
import { graphql } from "gatsby";

import PageTemplate from "src/components/PageTemplates/PageTemplate/PageTemplate";

const Page = ({ data: { wpPage }, location }) => {
  return <PageTemplate gatsbyData={wpPage} location={location} />;
};

export const query = graphql`
  query currentPageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      link
      template {
        templateName
      }
      title
      content
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
      acfPage {
        title
        subTitle
        content
      }
      acfHomePage {
        title
        slide {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
              }
            }
          }
          label
        }
      }
      acfAboutPage {
        businessAreas {
          businessArea
          businessDescription
        }
        contacts {
          contact {
            ... on WpStaff {
              title
              acfStaffPage {
                role
                mail
                phoneNumbers {
                  number
                }
              }
            }
          }
        }
      }
      acfPageSeo {
        seoTitle
        seoDescription
        seoImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200, height: 628)
            }
          }
        }
      }
    }
  }
`;

export default Page;

// TODO: Prop types
